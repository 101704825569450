<template>
  <div :class="device">
    <Banner/>
    <ContentLoading type="banner" v-if="!loaded"/>
    <h1 class="flying-advertisement text-center pt-5" v-if="!hideCondition">{{ $t("home.flying-advertisement")}}</h1>
    <TopBanner :topBanner="topBanner" v-if="showTopBanner && !hideCondition"/>

    <div v-if="!dealData && !hideCondition" class="loading-message">{{ $t('home.deal-loading-message') }}</div>
    <Lesiurpackages :promotionData="dealData" v-if="dealData && !!dealData.length && !hideCondition && !hideSlider" />
    <ContentLoading v-if="!dealData && !hideCondition" type="deal" />
    <Lesiurtabs
      :dealType="dealType"
      :dealData="dealData"
      :banner="middleBanner"
      :bannerHref="middleBannerHref"
      :showBanner="showMiddleBanner"
      v-if="loaded && !!dealData && !!middleBanner && !hideCondition"
    />
    <Footer page="home" v-if="!hideFooter"/>

  </div>
</template>

<script>
import TemplateHome from './TemplateHome';

export default {
  name: 'Home2',
  mixins: [TemplateHome],
  components: {
    Banner: () => import('@/components/content/Banner/BannerTheme2'),
    TopBanner: () => import('@/components/home/topBanner/TopBannerTheme2'),
    Lesiurpackages: () => import('@/components/home/Lesiurpackages/LesiurpackagesTheme2'),
    Lesiurtabs: () => import('@/components/home/lesiurtabs/LesiurtabsTheme2'),
    Footer: () => import('@/components/content/footer/FooterTheme0'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
  },
};
</script>

<style>
  @media (min-width: 1520px){
    .container {
      max-width: 1574px;
    }
  }
  @media(max-width: 1399px) {
    .container {
      max-width: 1320px;
    }
  }
</style>

<style scoped lang="scss">
  .flying-advertisement {
    font-family: "ploni";
    font-weight: 'bold';
    text-transform: 'capitalize';
    font-size: 48px;
    color: #000000;
    margin-bottom: 24px;
    margin-top: 70px;
  }

  .mobile{
    .flying-advertisement {
      font-size: 2rem;
      padding: 5px;
      margin-bottom: 0px;
      padding-top: 30px !important;
      margin-top: 0px;
    }
  }
</style>
